<template>
    <div>
        <div style="max-width: 1400px;" class="mx-auto mt-3 mb-2">
            <h1>سجل البلاغات ( {{ reports ? reports.length : ' ... ' }} )</h1>
            <div v-if="reports && reports.length == 0" class="text-center mt-4">
                <h2 class="text-muted mb-1">لا يوجد لديك بلاغات</h2>
                <b-button variant="primary" pill class="px-3" :to="{name: 'new-report'}"><feather-icon icon="PlusIcon" class="mr-25"/>قدم بلاغ</b-button>
            </div>
            <b-card class="border-primary" v-for="(report, index) in reports" :key="index">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex">
                        <b-avatar class="mr-1" size="60" rounded variant="light-primary">
                            <feather-icon icon="FileTextIcon" size="30" />
                        </b-avatar>
                        <div>
                            <h3 class="mb-25">بلاغ رقم <span dir="ltr" style="/* <-- إهتمام في التفاصيل */">#{{ report.id }}</span> - <span :class="'text-' + status[report.status].variant">{{ status[report.status].name }}</span>
                            </h3>
                            <p>
                                {{ getDateFormat(report.created_at) }}
                            </p>
                        </div>
                    </div>
                    <div>
                        <b-button variant="flat-primary" size="lg" :to="{ name: 'report', params: { id: report.id } }">
                            تفاصيل البلاغ
                            <feather-icon icon="ChevronLeftIcon" class="ml-25" size="22" />
                        </b-button>
                    </div>
                </div>

            </b-card>
        </div>
    </div>
</template>

<script>
import { BButton, BCard, BCardText, BLink, BImg, BAvatar } from 'bootstrap-vue'

import { library } from "@fortawesome/fontawesome-svg-core";
import { faCarBurst } from "@fortawesome/free-solid-svg-icons";

library.add(faCarBurst);

import Ripple from 'vue-ripple-directive'

import { getDateFormat } from '@core/utils/format';

export default {
    components: {
        BAvatar,
        BImg,
        BButton,
        BCard,
        BCardText,
        BLink,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            getDateFormat,
            reports: null,
            status: {
                1: { variant: 'warning', name: 'تحت المراجعة' },
                2: { variant: 'success', name: 'تمت المعالجة' },
                0: { variant: 'secondary', name: 'تم التنازل' },
            }
        }
    },
    created() {
        fetch('https://api.njm.solutions/history', {
            credentials: 'include',
            method: 'GET',
        })
            .then(res => res.json()).then(res => {
                if (res.status == 200) {
                    this.reports = res.data;
                } else {
                    alert('خطأ في إحضار سجل البلاغات')
                }
            })
    }
}
</script>

<style lang="scss" scoped>
p {
    margin-bottom: 0 !important;
}
</style>